import { ref } from "vue";
import c3api from "../c3api";

export default function useCsvUploader() {
	const clients = ref([]);

	const uploadTypes = ref([]);

	const fetchUploadTypes = async () => {
		const response = await c3api.get(`/my/uploader_types`);
		uploadTypes.value = response.data.data;
	};

	const fetchUploaderTemplate = async (template) => {
		const params = {
			template: template,
		};
		const response = await c3api.get(`/${template}/uploader`);
		return response.data.data;
	};

	const uploadCsvToServer = async (template, data) => {
		const response = await c3api.post(`/${template}/uploader`, data);
		return response;
	};

	const fetchClients = async (selectedClient = "") => {
		const urlParams = {
			filter: {
				name: {
					type: "contains",
					filter: selectedClient,
				},
			},
			sortModel: [
				{
					sort: "asc",
					colId: "name",
				},
			],
			limit: 100,
		};

		const response = await c3api.get(`/clients`, { params: urlParams });
		const tempReponse = response.data.data;
		clients.value = tempReponse;
	};

	return {
		clients,
		uploadTypes,
		fetchUploadTypes,
		uploadCsvToServer,
		fetchClients,
		fetchUploaderTemplate,
	};
}
