<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { createCSVImporter } from "csv-import-js";
import useCsvUpload from "@/hooks/useCsvUpload";

const DOWNLOAD_TEMPLATE_BUTTON = "Download Template";
const COLOR_INDIGO = '#7E5EF8';
const COLOR_WHITE = "white";

const { fetchUploaderTemplate } = useCsvUpload();

const props = defineProps({
  uploaderTemplate: {
    type: String,
    required: true,
  },
  client: {
    type: String,
    required: true,
  },
  callback: {
    type: Function,
    required: true,
  },
});

const importerContainer = ref(null);
let importer: any = null;

onMounted(async () => {
  await createImporter();
});

watch(props, async () => {
  await createImporter();
});

async function createImporter() {

  const template = await fetchUploaderTemplate(props.uploaderTemplate);
  console.log("current csv template:", template);

  try {
    importer = createCSVImporter({
      isModal: false,
      domElement: importerContainer.value,
      onComplete: async (data: any) => {
        props.callback(data, createImporter);
      },
      darkMode: false,
      template: {
        columns: template,
      },
      skipHeaderRowSelection:true
    });

    addStylings();

  } catch (error) {
    console.error("Error creating CSV importer", error);
  }
}

/**
 * Add stylings to the table if needed. Tableflow adds dynamic class names to the components. E.g. for the download template
 * button, the class name is: csv-importer-1wtfepp. This might be subject to change, so cannot rely on these names.
 */
const addStylings = () => {
  styleChakraButtons();
  styleTooltip();
}

const styleChakraButtons = () => {
  const buttons = document.querySelectorAll('.chakra-button');
  buttons.forEach(function(button) {
      if (button.innerText === DOWNLOAD_TEMPLATE_BUTTON) {
          // Add your styling or other actions here
          button.style.backgroundColor = COLOR_INDIGO;
          button.style.color = COLOR_WHITE;
      }
  });
}

const styleTooltip = () => {
  const tBodyRow = document.querySelectorAll('[class^="Default-module_tr"]')
  tBodyRow.forEach((row) => {
    row.style.position = "relative";
    row.style.overflow = "visible";
  });
  
  const styles = document.styleSheets
  for (let i = 0; i < styles.length; i++) {
    const rules = styles[i].cssRules;
    for (let i = 0; i < rules.length; i++) {
      if (rules[i].selectorText && rules[i].selectorText.startsWith('.Tooltip-module_message')) {
          rules[i].style.setProperty('position', 'absolute', 'important');
          rules[i].style.setProperty('top', '10px', 'important');
          rules[i].style.setProperty('left', '82px', 'important');
          rules[i].style.setProperty('width', 'max-content', 'important');
        }
    }
  }
}

</script>

<template>
    <div ref="importerContainer"></div>
</template>

<style>

  .CSVImporter-div  {
    height: 650px;  
  }

</style>